.fixed-background {
    display: flex;
    height: 100%;
    z-index: 1;
    left: 0px;
    top: 0px;
    background: rgba(255,255,255,0.1);
    width: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
}

.card-container  {
    width: 400px !important;
    box-shadow: 0px 0px 9px 0px rgba(39, 33, 33, 0.3);
    padding: 30px !important;
    border-radius: 10px;
}

.logo-img {
    height: 150px;
}