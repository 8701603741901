body {
	font-family: 'Noto Sans', sans-serif;
	width: 100%;
	height: 100%;
	padding: 0px;
	font-size: 15px;
	background-color: #FFFFFF;
	margin: 0;
}

a, a:hover, a:focus, a:active {
    color: inherit;
    text-decoration: inherit;
    color: #525a60;
}

textarea {
    resize: none;
}

input:focus-visible,
select:focus-visible,
textarea:focus-visible{
	outline: unset;
}

::-webkit-input-placeholder {
    color: #77838c;
    font-style: italic;
}
:-moz-placeholder {
    color: #77838c;
    font-style: italic;  
}
::-moz-placeholder {
    color: #77838c;
    font-style: italic;  
}
:-ms-input-placeholder {
    color: #77838c; 
    font-style: italic; 
}

.primary-container {
    background-color: #fff;
    transition: ease 0.5s all;
    height: 100vh !important;
    overflow: auto;
}

.primary-button {
    background-color: white;
    color: #76b4ff;
    border-radius: 5px;
    cursor: pointer;
    padding: .5rem;
    font-family: 'Noto Sans', sans-serif;
    border: 2px solid #76b4ff;
    font-size: 1.1em;
    transition: ease 0.5s all;
    margin: .5rem;
}

.primary-button:hover {
    background-color: #76b4ff;
    color: white;
}

.menu-container {
    width: 20% !important;
    height: 100% !important;
    padding: 0;
    margin: 0;
}

.content-container {
    width: 80%;
    overflow: auto;
}

.field {
	padding: .5rem;
}

.field.error label,
.field.error span {
    color: #ff4d4d;
}

.field.error input:not(.react-date-picker__inputGroup__input),
.field.error .react-date-picker,
.field.error textarea {
    color: #ff4d4d;
    border: 1px solid #ff4d4d;
    border-radius: 5px;
}

.field.error select {
    border: 1px solid #ff4d4d;
}

.field.error input[type='checkbox'] {
    border: 1px solid #ff4d4d;
    outline: 1px solid #ff4d4d;
}

.input-field {
	color: #525a60;
	border: none;
	border: 1px solid #c0c5c9;
	padding: .5rem;
    border-radius: 5px;
}

.field select {
	height: 40.5px;
}

.back-button {
    text-decoration: none;
    color: #525a60;
    font-size: 1.25em;
    transition: all ease .2s;
    background: transparent;
    border: none;
}

.back-button:hover {
    color: #76b4ff;
}

.list-table {
	width: 100%;
	margin: auto;
	text-align: center;
	border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #c0c5c9; /* this draws the table border  */ 
}

.list-table thead tr th {
	font-size: 1.3em;
	font-weight: 500;
	cursor: default;
}

.list-table tr th,
.list-table tr td {
	border: 1px solid #c0c5c9;
	padding: .5rem;
}

.list-table tr td {
	font-size: 1.1em;
	cursor: pointer;
    white-space: pre-line;
}

.list-table tr td a,
.list-table tr td span {
    color: #525a60;
    display:inline-block;
    width: 100%;
}

.list-table tbody tr:hover{
	background: #76b4ff;
	transition: 0.5s all ease;
}

.list-table tbody tr:hover td a,
.list-table tbody tr:hover td span {
	color: white;
}

.list-table tbody tr:hover td .trash-icon {
	color: white;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

.dropdown-container {
    display: none;
    width: 96.5%;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    position: absolute;
    top: 90%;
    z-index: 1;
}

.dropdown-container.l {
    width: 97.5% !important;
}

.dropdown-container.active {
    display: block;
}

.dropdown-item-container {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525a60;
    cursor: pointer;
    transition: ease 0.3s all;
    padding: 0.25rem;
    border-radius: 0.25rem;
}

.dropdown-item-container:hover {
    background-color: #76b4ff;
    color: white;
}

.dropdown-message-item {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525a60;
    padding: 0.25rem;
}

.dropdown-message-item a {
    transition: ease 0.3s all;
}

.dropdown-message-item a:hover {
    color: #76b4ff;
}

.add-icon-button {
    background-color: white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    border: 2px solid #76b4ff;
    font-size: 1.1em;
    transition: ease 0.5s all;
    color: #76b4ff;
}

.add-icon-button:hover {
    background-color: #76b4ff;
    color: white;
}

.delete-button {
    background-color: white;
    font-size: 1.1em;
    transition: ease 0.2s all;
    color: #525a60;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    cursor: pointer;
}

.delete-button:hover {
    transform: scale(1.2);
    color: #ff4d4d;
}

.react-date-picker__wrapper,
.react-datepicker-wrapper {
    border: none !important;
    border: 1px solid #c0c5c9 !important;
    padding: 0.5rem !important;
    border-radius: 5px;
}

.react-datepicker-wrapper input {
    border: none !important;
}

input[type="file"] {
    display: none;
}

.file-upload {
    display: inline-block;
	color: #525a60;
	border: none;
	border: 1px solid #c0c5c9;
	padding: .5rem;
    border-radius: 5px;
    cursor: pointer;
}

.preview-signature {
    width: 300px;
    height: 150px;
    object-fit: contain;
}

.signature {
    width: 160px;
    height: 80px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
}

.label-field {
    color: #4e556a;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.label-field.request {
    color: #76b4ff;
}

.span-field {
    color: #4e556a;
}

.eye-icon {
    position: absolute;
    bottom: 22.5%;
    right: 3%;
    cursor: pointer;
    font-size: 1.3em;
    color: #4e556a;
    transition: ease 0.2s all;
    width: fit-content;
}

.eye-icon:hover {
    color: #76b4ff;
}

.inline-text {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.search-fields {
    flex: 1 1 0px;
}

.search-button {
    background-color: #76b4ff;
    width: 3rem;
    height: 3rem;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: ease 0.2s all;
    font-size: 1.1em;
    align-self: center;
}

.search-button:hover {
    font-size: 1.3em;
}

.arrow-icon {
    transition: ease 0.2s all;
}

.delete-search-text {
    color: #525a60;
    cursor: pointer;
    transition: ease 0.2s all;
}

.delete-search-text:hover {
    color: #ff4d4d;
    text-decoration: underline;
}

.w-3 {
    width: 33.3%;
}

.w-20 {
    width: 20%;
}

.quantity-container {
    max-width: 100px;
}

.quantity-container input,
.state-container input,
.col input:not([type='checkbox']) {
    width: 100%;
    max-width: 100%;
}

.state-container {
    max-width: 150px;
}

.item-info-container {
    max-width: 200px;
}

.link-button {
    border: 1px solid gray;
    padding: 0.5rem 1rem;
    margin: .5rem;
    border-radius: 5px;
    transition: ease 0.5s all;
    text-align: center;
    color: gray;
    background-color: transparent;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    width: 31%;
}

.link-button:hover {
    border: 1px solid #76b4ff;
    background-color: #76b4ff;
    color: white;
}

.lawer-logo {
    width: 18%;
    align-self: end;
}

.document-container {
    background-color: #fff;
    width: 210mm;
    min-height: 297mm;
    padding: 1.5rem 4rem !important;
    align-self: center;
}

.inventory-item {
    border: 1px solid #f2f2f2;
}

.contract-container {
    border: 1px solid rgba(39, 33, 33, 0.3);
    border-radius: 5px;
    padding: 1rem;
    transition: 0.2s all ease;
}

.contract-container:hover {
	box-shadow: 1px 1px 5px 0px rgba(128,128,128,.50);
}

.contract-container.ok {
	border-left: 10px solid #9ffc74;
}

.contract-container.pending {
	border-left: 10px solid #f58142;
}

.contract-container.mora {
	border-left: 10px solid #d11919;
}

.pending-item {
    background-color: #f0d032;
    color: #fff !important;
}

.table thead tr {
    border-bottom: 1px solid #212529;
}

.table th,
.table td {
    text-align: center; 
    vertical-align: middle;
}

.contract-text.ok {
	color: #212529;
}

.contract-text.pending {
	color: #f58142;
}

.contract-text.mora {
	color: #d11919;
}

.request-header-container {
    justify-content: space-between;
    margin: 2rem 0;
}

.request-header {
    order: 1;
    margin: 0;
}

.request-logo {
    width: 15%;
    order: 2;
}

.end-button {
    max-width: 250px;
    margin: .5rem;
    transition: ease 0.5s all;
    color: #525a60;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.end-button-wm {
    transition: ease 0.5s all;
    color: #525a60;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.end-button.delete:hover {
    text-decoration: underline;
    color: #ff4d4d;
}

.end-button.go:hover,
.end-button-wm:hover {
    text-decoration: underline;
    color: #76b4ff;
}

@media only screen and (max-width: 1366px) {
    h1 {
        font-size: 2em;
    }

    h4 {
        font-size: 1.25em;
    }

    .primary-button {
        padding: .25rem;
        font-size: .9em;
        margin: .25rem;
    }

    .back-button {
        font-size: 1em;
    }

    .list-table thead tr th {
        font-size: 1.1em;
    }
    
    .list-table tr th,
    .list-table tr td {
        padding: .25rem;
    }
    
    .list-table tr td {
        font-size: 1em;
    }

    .input-field:not( textarea ) {
        padding: .25rem;
        font-size: .9em;
        height: 37px;
    }

    .field select {
        height: 37px;
    }

    .react-date-picker__wrapper {
        padding: 0.25rem !important;
    }

    .search-button {
        width: 2.25rem;
        height: 2.25rem;
    }

    .search-button:hover {
        font-size: 1.2em;
    }

    .label-field,
    .span-field {
        font-size: .85em;
    }
}

@media only screen and (max-width: 900px){
    .w-25 {
        width: 100% !important;
    }

    .request-header-container {
        justify-content: center;
        margin: 1rem 0;
    }

    .request-header {
        margin-top: 2rem;
        width: 100%;
        order: 2;
    }

    .request-logo {
        width: 40%;
        order: 1;
    }
}