.menu-primary-container {
    border-right: 3px solid #808080;
}

.list-content a {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #525a60;
    font-size: 1.25em;
    transition: all ease .2s;
}

.list-content a.tag {
    font-weight: bolder;
}

.list-content a.sublink {
    padding: 0 2rem;
}

.list-content a:hover {
    color: #76b4ff;
}

.list-content a.active {
    color: #76b4ff;
}

.user-info-text {
    font-size: .9em;
    margin: -3px 0;
}

.logout-button {
    cursor: pointer;
}

@media only screen and (max-width: 1366px) {
    .logo-img {
        height: 100px;
    }

    .user-info-text {
        font-size: .9em;
    }

    .user-info-text.name {
        margin-top: 2rem !important;
    }

    .list-content a {
        padding: 0.25rem .5rem;
        font-size: .9em;
        transition: all ease .2s;
    }
    
    .list-content a.tag {
        font-weight: bolder;
    }
    
    .list-content a.sublink {
        padding: 0 2rem;
    }
}

